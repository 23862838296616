import { addClassList } from "./utils/addClassList";
import { appendToParent } from "./utils/appendToParent";
import { createElement } from "./utils/createElement";
import { getAttributes } from "./utils/getAttributes";
import { getElementById } from "./utils/getElementById";
class BuyerCall {
  constructor({
    classList = "",
    buttonImgurl = "",
    subTitle = "",
    colorPrimary,
    colorSecondary,
    infoSectionBackground,
    url = "http://localhost:3000/",
    title = "Launch Button",
    parentDiv,
    isModal = false,
    buyyerElement,
    buyerCallInputs = {},
    customerDetails = {},
    innerHTML = "widget button",
  } = {}) {
    this.buttonImgurl = buttonImgurl;
    this.innerHTML = innerHTML;
    this.customerDetails = customerDetails;
    this.colorPrimary = colorPrimary;
    this.colorSecondary = colorSecondary;
    this.infoSectionBackground = infoSectionBackground;
    this.isModal = isModal;
    this.url = url;
    this.classes = classList;
    this.lauchButton = buyyerElement;
    this.buttonTitle = title;
    this.buttonSubTitle = subTitle;
    this.open = false;
    this.parentDiv = parentDiv;
    this.buyerCallInputs = buyerCallInputs;
    this.initialize();
    this.createStyles();
  }
  initialize() {
    let classesArray = [];
    if (this.classes) {
      classesArray = this.classes.split(" ");
    }
    this.modalContainer = createElement("div");
    addClassList(this.modalContainer, [
      "cta__buyerCall__modalLaunch__hidden",
      "cta__buyerCall__modalLaunch__modal_container",
    ]);

    this.iframe = createElement("iframe");
    addClassList(
      this.iframe,
      "cta__buyerCall__modalLaunch__modal_container_iframe"
    );
    this.iframe.src = this.url; //url of the widget modal
    this.iWindow = "";
    this.iframe.addEventListener("load", () => {
      this.iWindow = this.iframe.contentWindow;
      this.lauchButton.disabled = false;

      if (this.isModal == "false") {
        this.modalOpen.call(this);
      }
    });

    classesArray.forEach((item) => addClassList(this.lauchButton, item));

    if (this.isModal == "true") {
      addClassList(
        this.lauchButton,
        "cta__buyerCall__modalLaunch_buttonLaunch"
      );
      this.titleContainer = createElement("div");
      addClassList(this.titleContainer, "cta__buyerCall_titlecontainer");

      //set title
      this.title = createElement("h4");
      this.title.innerHTML = this.buttonTitle;
      appendToParent(this.titleContainer, this.title);

      //set sub-title
      this.subTitle = createElement("h6");
      this.subTitle.innerHTML = this.buttonSubTitle;
      appendToParent(this.titleContainer, this.subTitle);

      appendToParent(this.lauchButton, this.titleContainer);

      this.lauchButton.disabled = true;
      this.lauchButton.addEventListener("click", this.modalOpen.bind(this));
      appendToParent(this.parentDiv, this.lauchButton);
      appendToParent(this.parentDiv, this.modalContainer);

      if (this.buttonImgurl) {
        this.imgContainer = createElement("div");
        addClassList(this.imgContainer, "cta__buyerCall_imgContainer");
        this.img = createElement("img");
        this.img.src = this.buttonImgurl;
        appendToParent(this.imgContainer, this.img);
        appendToParent(this.lauchButton, this.imgContainer);
      }
    } else {
      this.modalContainer.classList.remove(
        "cta__buyerCall__modalLaunch__hidden"
      );
      this.modalContainer.classList.remove(
        "cta__buyerCall__modalLaunch__modal_container"
      );

      addClassList(this.modalContainer, "cta__buyerCall__modalLaunch__screen");
      addClassList(
        this.lauchButton,
        "cta__buyerCall__modalLaunch_isModal_false"
      );

      appendToParent(this.lauchButton, this.modalContainer);
    }
    appendToParent(this.modalContainer, this.iframe);

    window.addEventListener("message", (event) => {
      // extract the data from the message event
      const { data } = event;
      if (!data && this.isModal=="true") {
        this.open = false;
        addClassList(
          this.modalContainer,
          "cta__buyerCall__modalLaunch__hidden"
        );
      } else {
        this.open = false;
        // window.responseIframeData = { ...data };
      }
    });
  }
  //function for open the modal
  modalOpen() {
    window.responseIframeData = {};
    this.open = !this.open;
    let inputData = {
      isModal: this.isModal,
      customerDetails: this.customerDetails,
      infoSectionBackground: this.infoSectionBackground,
      colorPrimary: this.colorPrimary,
      colorSecondary: this.colorSecondary,
      ...this.buyerCallInputs,
    };
    if (this.open) {
      this.iWindow.postMessage(inputData, this.url); //pass data to be populated in modal and url of the widget modal
      this.modalContainer.classList.remove(
        "cta__buyerCall__modalLaunch__hidden"
      );
    } else {
      addClassList(this.modalContainer, "cta__buyerCall__modalLaunch__hidden");
    }
  }
  createStyles() {
    const styleTag = createElement("style");
    document.head.appendChild(styleTag);

    styleTag.innerHTML = `
      .cta__buyerCall__modalLaunch_buttonLaunch {
        width:100%;
        min-height:70px;
        height:100%;
        background-color: #334049;
        border-radius: 8px;
        border-style: none;
        box-sizing: border-box;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        list-style: none;
        margin: 0;
        outline: none;
        padding: 10px 16px;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: color 100ms;
        vertical-align: baseline;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;  
      }
      .cta__buyerCall__modalLaunch_isModal_false{
        min-height:950px;
        display: flex;
      }
      @media (max-width: 576px) {
        .cta__buyerCall__modalLaunch_isModal_false{
          min-height:2050px;
          display: flex;
        }
      }
      .cta__buyerCall__modalLaunch_buttonLaunch:hover,
      .cta__buyerCall__modalLaunch_buttonLaunch:focus {
          background-color: #29333a;
      }
     .cta__buyerCall_imgContainer {
      width: 30%;
      margin: auto;
      display: block;
     }
     .cta__buyerCall_titlecontainer {
      width: 100%;
      height: 100%;
      margin: auto;
      display: block;
      padding-right: 5%;
      justify-content: center;
      align-items: normal;
      text-align: left;
      flex-direction: column;
     }
     .cta__buyerCall_titlecontainer > h4 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
     }
     .cta__buyerCall_titlecontainer > h6 {
      padding: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-top: 4px;
     }
     .cta_widget_footerImg {
      width: 10%;
     }
     .cta__buyerCall_imgContainer > img{
      width: 80%;
      height: 100%;
      padding-horizontal: 5%;
     }

      .cta__buyerCall__modalLaunch__hidden {
        display: none;
      }
      .cta__buyerCall__modalLaunch__screen {
        width: 100%;
      }
      .cta__buyerCall__modalLaunch__button_container {
        background-color: transparent;
        width: "100%";
        height: "100%";
      }
  
      .cta__buyerCall__modalLaunch__modal_container {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 3000;
        display: block;
        background-color: transparent;
        width: 100%;
        height: 100%;
      
      }
      .cta__buyerCall__modalLaunch__modal_container.cta__buyerCall__modalLaunch__hidden {
        display: none;
      }
  
      .cta__buyerCall__modalLaunch__modal_container_iframe {
        background-color: transparent;
        width: 100%;
        height: 100%;
        border: 0;
      }
      @media (max-width: 992px) {
        .cta__buyerCall_imgContainer > img {
          width: 100%;
        }
      }
      
   `;
  }
}

window.addEventListener("DOMContentLoaded", function () {
  const buyyerCallWidgetDiv = document.querySelectorAll(
    ".cta__buyerCall__modalLaunch"
  );
  let formIndex = 0;
  buyyerCallWidgetDiv.forEach((elem) => {
    if (elem.tagName == "DIV") {
      const isModal = getAttributes(elem, "isModal");
      const buyyerCallWidgetButton = this.document.createElement("button");
      const parentDiv = elem.parentNode;
      if (isModal == "true") {
        elem.style.display = "none";
        const buyerCall = new BuyerCall({
          buyyerElement: buyyerCallWidgetButton,
          isModal,
          parentDiv,
          classList: getAttributes(elem, "classList"),
          url: getAttributes(elem, "url"),
          title: getAttributes(elem, "title"),
          buyerCallInputs: JSON.parse(getAttributes(elem, "buyerCallInputs")),
          subTitle: getAttributes(elem, "subTitle"),
          innerHTML: elem.innerHTML,
          colorPrimary: getAttributes(elem, "colorPrimary"),
          colorSecondary: getAttributes(elem, "colorSecondary"),
          buttonImgurl: getAttributes(elem, "button-img-url"),
          customerDetails: JSON.parse(getAttributes(elem, "customerDetails")),
          infoSectionBackground: getAttributes(elem, "infoSectionBackground"),
        });
      } else {
        //for single form
        formIndex++;
        if (formIndex == 1) {
          const buyerCall = new BuyerCall({
            buyyerElement: elem,
            isModal,
            parentDiv,
            classList: getAttributes(elem, "classList"),
            url: getAttributes(elem, "url"),
            colorPrimary: getAttributes(elem, "colorPrimary"),
            colorSecondary: getAttributes(elem, "colorSecondary"),
            infoSectionBackground: getAttributes(elem, "infoSectionBackground"),
            buyerCallInputs: JSON.parse(getAttributes(elem, "buyerCallInputs")),
            customerDetails: JSON.parse(getAttributes(elem, "customerDetails")),
          });
        }
      }
    }
  });
});
